import React from 'react'

import * as FileSaver from 'file-saver';

import * as XLSX from 'xlsx';
import { Button } from 'reactstrap';
import ExportIcon from "../../assets/img/dashboard-svgs/download-square-svgrepo-com.svg"


export const ExportCSV = ({ csvData, fileName, isDisable }) => {



    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const fileExtension = '.xlsx';



    const exportToCSV = (csvData, fileName) => {

        const ws = XLSX.utils.json_to_sheet(csvData);

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, fileName + fileExtension);

    }



    return (

        // <Button className="btn btn-secondary br-0 download-as-excel" disabled={isDisable} onClick={(e) => exportToCSV(csvData, fileName)} title='Download as Excel'>
            <img className='download-as-excel' src={ExportIcon} title='Download as Excel' disabled={isDisable} onClick={(e) => exportToCSV(csvData, fileName)} />
        // </Button>

    )

}

import React from "react";
import PropTypes from "prop-types";
import NavbarDropdown from "./NavbarDropdown";
import NavbarDropdownComponents from "./NavbarDropdownComponents";
import {
  authenticationRoutes,
  chatRoutes,
  componentRoutes,
  ECommerceRoutes,
  emailRoutes,
  homeRoutes,
  dashboardRoutes,
  master,
  AnalyticsRoutes,
  operations,
  userManagement,
  scheduleRoutes,
  tvod,
  avod,
  demands,
  campaign,
  demandReports,
  adsText,
  svod,
  otherReports,
  configSettings,
  marketing,
  pageRoutes,
  pluginRoutes,
  utilityRoutes,
  widgetsRoutes,
  Ecommerce,
  contentPartnerAvod,
  EcommerceAnalytics,
  FinanceRoute,
  affiliateRoutes
} from "../../routes";
import { NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { breakpoints } from "../../helpers/utils";
import { topNavbarBreakpoint } from "../../config";

const NavbarTopDropDownMenus = ({ setNavbarCollapsed }) => {
  const components = [
    { children: [] },
    demands,
    campaign,
    demandReports,
    adsText,
  ];
  const pages = [
    pageRoutes,
    widgetsRoutes,
    chatRoutes,
    emailRoutes,
    ECommerceRoutes,
  ];
  const handleSetNavbarCollapsed = () => {
    const windowWidth = window.innerWidth;
    windowWidth < breakpoints[topNavbarBreakpoint] && setNavbarCollapsed(false);
  };
  const cmsRoutes = dashboardRoutes();
  const svodRoutes = svod();
  const tvodRoutes = tvod();
  const contentPartnerAvodRoutes = contentPartnerAvod();

  const masterRoutes = master();
  const analyticsRoutes = AnalyticsRoutes()
  // const operationsRoutes = operations();
  const userManagementRoutes = userManagement();
  const otherReportsRoutes = otherReports();
  const eCommerceRoutes = Ecommerce();
  const eCommerceAnalyticsRoutes = EcommerceAnalytics();
  const configSettingsRoutes = configSettings();
  const FinanceRoutes = FinanceRoute();
  const marketingRoutes = marketing();
  const pub_id = localStorage.getItem("pub_id");
  const getPublisherConfigInfo = keyName => {
    let response = false;
    if(localStorage.getItem("publisherConfig") && (typeof localStorage.getItem("publisherConfig") !== 'undefined' && (localStorage.getItem("publisherConfig") !=='undefined'))){
    var publisherConfig = JSON.parse(localStorage.getItem("publisherConfig"));
    if (publisherConfig) {
      let configInfo = publisherConfig.filter(i => keyName == i.key_name);
      response = configInfo[0] && configInfo[0].key_value;
      return response;
    }
   }
    return response;
  }


  let mainRoutes = (
    <>
    {localStorage.getItem("role_id")!=7&&
      <NavbarDropdown
        title={homeRoutes.name}
        items={homeRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />}
      <NavbarDropdown
        title={masterRoutes.name}
        items={masterRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />
         {localStorage.getItem("role_id")==7&&
      <NavbarDropdown
        title={analyticsRoutes.name}
        items={analyticsRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />}
      {
      localStorage.getItem("role_id")!=7 &&( 
        <NavbarDropdown
        title={cmsRoutes.name}
        items={cmsRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />)
      }
      {/* {
      localStorage.getItem("role_id")!=7 && operationsRoutes && operationsRoutes?.children?.length>0 &&
      <NavbarDropdown
        title={operationsRoutes.name}
        items={operationsRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />} */}
      {
      localStorage.getItem("role_id")!=7 &&
      <NavbarDropdown
        title={userManagementRoutes.name}
        items={userManagementRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />}
      {getPublisherConfigInfo("linear_needed") == "True" ? (
        <NavbarDropdown
          title={scheduleRoutes.name}
          items={scheduleRoutes.children}
          handleSetNavbarCollapsed={handleSetNavbarCollapsed}
        />
      ) : null}

      {getPublisherConfigInfo("tvod_needed") == "True" ? (
        <NavbarDropdown
          title={tvodRoutes.name}
          items={tvodRoutes.children}
          handleSetNavbarCollapsed={handleSetNavbarCollapsed}
        />
      ) : null}

      {getPublisherConfigInfo("avod_needed") == "True" ? (
        <NavbarDropdownComponents
          title={avod.name}
          items={components}
          handleSetNavbarCollapsed={handleSetNavbarCollapsed}
        />
      ) : null}

      {getPublisherConfigInfo("svod_needed") == "True" ? (
        <NavbarDropdown
          title={svodRoutes.name}
          items={svodRoutes.children}
          handleSetNavbarCollapsed={handleSetNavbarCollapsed}
        />
      ) : null}

      {
      localStorage.getItem("role_id")!=7 &&
      <NavbarDropdown
        title={otherReportsRoutes.name}
        items={otherReportsRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />}
      {
      // localStorage.getItem("role_id")!=7 &&
      <NavbarDropdown
      title={affiliateRoutes.name}
      items={affiliateRoutes.children}
      handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />}
      {(getPublisherConfigInfo("ecommerce_needed") == "True" ||
        localStorage.getItem("role_id") == 12) && (
        <>
          <NavbarDropdown
            title={eCommerceRoutes.name}
            items={eCommerceRoutes.children}
            handleSetNavbarCollapsed={handleSetNavbarCollapsed}
          />
          <NavbarDropdown
            title={eCommerceAnalyticsRoutes.name}
            items={eCommerceAnalyticsRoutes.children}
            handleSetNavbarCollapsed={handleSetNavbarCollapsed}
          />
        </>
      )}

{/* {getPublisherConfigInfo("settings_needed") == "True" ? (
      <NavbarDropdown
        title={configSettingsRoutes.name}
        items={configSettingsRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />):null} */}
      {localStorage.getItem("superadmin") ? (
      <NavbarDropdown
        title={configSettingsRoutes.name}
        items={configSettingsRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />):null}

      {localStorage.getItem("role_id")!=7 &&<NavbarDropdown
        title={marketingRoutes.name}
        items={marketingRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />}
      {localStorage.getItem("role_id") == 14 ? (
        <NavbarDropdown
          title={FinanceRoutes.name}
          items={FinanceRoutes.children}
          handleSetNavbarCollapsed={handleSetNavbarCollapsed}
        />
      ) : null}
      {/* <NavbarDropdown
      title={cmsRoutes.name}
      items={cmsRoutes.children}
      handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      
    /> 
    <NavbarDropdown title={cmsRoutes.name} items={cms} handleSetNavbarCollapsed={handleSetNavbarCollapsed} />
      <NavbarDropdown title={pageRoutes.name} items={pages} handleSetNavbarCollapsed={handleSetNavbarCollapsed} />
      <NavbarDropdown
        title={authenticationRoutes.name}
        items={authenticationRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />

      <NavItem onClick={handleSetNavbarCollapsed}>
        <NavLink tag={Link} to="/documentation">
          Documentation
        </NavLink>
      </NavItem> */}
    </>
  );
  let contentPartnerMainRoutes = (
    <>
      <NavbarDropdown
        title={homeRoutes.name}
        items={homeRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />
      <NavbarDropdown
        title={cmsRoutes.name}
        items={cmsRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />
      {getPublisherConfigInfo("tvod_needed") == "True" ? (
        <NavbarDropdown
          title={tvodRoutes.name}
          items={tvodRoutes.children}
          handleSetNavbarCollapsed={handleSetNavbarCollapsed}
        />
      ) : null}
      {getPublisherConfigInfo("avod_needed") == "True" ? (
        <NavbarDropdown
          title={contentPartnerAvodRoutes.name}
          items={contentPartnerAvodRoutes.children}
          handleSetNavbarCollapsed={handleSetNavbarCollapsed}
        />
      ) : null}
      {getPublisherConfigInfo("svod_needed") == "True" ? (
        <NavbarDropdown
          title={svodRoutes.name}
          items={svodRoutes.children}
          handleSetNavbarCollapsed={handleSetNavbarCollapsed}
        />
      ) : null}
      <NavbarDropdown
        title={otherReportsRoutes.name}
        items={otherReportsRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />
    </>
  );
  let FinanceTeamRoutes = (
    <>
      <NavbarDropdown
        title={FinanceRoutes.name}
        items={FinanceRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />
    </>
  );
  return localStorage.getItem("role_id") == 8
    ? contentPartnerMainRoutes
    : localStorage.getItem("role_id") == 13
    ? FinanceTeamRoutes
    : mainRoutes;
};

NavbarTopDropDownMenus.propTypes = {
  setNavbarCollapsed: PropTypes.func.isRequired,
};

export default NavbarTopDropDownMenus;

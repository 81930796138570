import axios from 'axios';

export const getconfigData = async (pubid) => {
    const config = {
        headers: {      
          pubid: pubid,
        },
      };
    try {
        const response = await axios.get(process.env.REACT_APP_API_SERVER_HOST + '/config/data',config);
        return response.data;
    } catch (error) {
        return { data: [] };
    }
}

export const checkTokenAccess = async () => {
  const pubid = localStorage.getItem("pub_id")
  const accessToken = localStorage.getItem('access-token')
  const roleId = localStorage.getItem('role_id')
  const config = {
    headers: {
      "pubid": pubid,
      "access-token": accessToken,
      "role_id": roleId
    },
  };
  try {
    const response = await axios.post(process.env.REACT_APP_API_SERVER_HOST + '/auth/verify', null, config);
    return response.data;
  } catch (error) {
    return { error };
  }
}
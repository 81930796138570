const axios = require('axios');
const qs = require('querystring');

const getChannel = (pub_id) => {
    let url = `${process.env.REACT_APP_API_SERVER_HOST}/channel/list/${pub_id}`
    return axios.get(url, { headers: { 'pubid': pub_id } }).then(response => {
        return response.data
    }).catch(e => { return { data: false } })
}

function getCategoryList(channelId) {
    //header data
    const customConfig = {
        headers: {
            pubid: localStorage.getItem("pub_id"),
            channelid: localStorage.getItem("currentChannel"),
        },
    };
    let url = `${process.env.REACT_APP_API_SERVER_HOST}/page/list`;
    return axios
        .get(url, customConfig)
        .then((response) => {
            console.log(response)
            if (response.data.success === false) {
                return false;
            } else {
                return response.data;
            }
        })
        .catch((e) => {
            return false;
        });
}


const blogDetails = (pub_id,blogid) => {
    let url = `${process.env.REACT_APP_API_SERVER_HOST}/page/${blogid}`
    return axios.get(url,{ headers: { 'pubid': pub_id ,'channelid': localStorage.getItem("currentChannel")} ,params:{ id : blogid} }).then(response => {
        console.log(response.data.html);
        console.log()
        return response.data
    }).catch(e => { return { data: false } })
}


  const videoDetails = (pub_id) => {
    let url = `${process.env.REACT_APP_API_SERVER_HOST}/video/list`
    return axios.get(url,{ headers: { 'pubid': pub_id ,'channelid': localStorage.getItem("currentChannel")}}).then(response => {
        // console.log(response.data.html);
     console.log(pub_id)
        console.log(response)
        return response.data
    }).catch(e => { return { data: false } })
}





const deleteBlogList= (page_id) => {
    let url = `${process.env.REACT_APP_API_SERVER_HOST}/page/delete/${page_id}`
    return axios.post(url,null,{ headers: { 'pubid': localStorage.getItem('pub_id') , channelid: localStorage.getItem("currentChannel")}  }).then(response => {
        return response.data
    }).catch(e => { return { data: false } })
}


const publishBlogList= (page_id) => {
    const customConfig = {
        headers: {
            pubid: localStorage.getItem("pub_id"),
            channelid: localStorage.getItem("currentChannel"),
            pageid:page_id
        },
    };
    let url = `${process.env.REACT_APP_API_SERVER_HOST}/page/publish`;
    return axios.post(url, customConfig).then((response) => {
        if (response.data.success === false) {
            return false;

        } else {
            return response.data;
        }
    })
        .catch((e) => {
            return false;
        });
}

const updateDraftBlog = (requestBody) => {

    const customConfig = {
        headers: {
            pubid: localStorage.getItem("pub_id"),
            channelid: localStorage.getItem("currentChannel"),
        },
    };

    console.log('draftBlogInput', requestBody, customConfig)
    let url = `${process.env.REACT_APP_API_SERVER_HOST}/page/update`;
    return axios.post(url, requestBody, customConfig).then((response) => {
        console.log(response)
        console.log(requestBody)
        if (response.data.success === false) {
            console.log(response.data);
            return false;

        } else {
            return response.data;
        }
    })
        .catch((e) => {
            return false;
        });

}


const draftBlog = (requestBody) => {

    const customConfig = {
        headers: {
            pubid: localStorage.getItem("pub_id"),
            channelid: localStorage.getItem("currentChannel"),
        },
    };

    console.log('draftBlogInput', requestBody, customConfig)
    let url = `${process.env.REACT_APP_API_SERVER_HOST}/page/add`;
    return axios.post(url, requestBody, customConfig).then((response) => {
        console.log(response)
        console.log(requestBody)
        if (response.data.success === false) {
            console.log(response.data);
            return false;

        } else {
            return response.data;
        }
    })
        .catch((e) => {
            return false;
        });

}

const publishBlog = () => {


    const customConfig = {
        headers: {
            pubid: localStorage.getItem("pub_id"),
            channelid: localStorage.getItem("currentChannel"),
        },
    };
    let url = `${process.env.REACT_APP_API_SERVER_HOST}/page/publish`;
    return axios.post(url, customConfig).then((response) => {
        if (response.data.success === false) {
            return false;

        } else {
            return response.data;
        }
    })
        .catch((e) => {
            return false;
        });

}


function uploadImage(data, id) {
    const config = {
        headers: {
            pubid: localStorage.getItem('pub_id')
        },
        params: {
            is_show_video: 12
        }
    };
    const formData = new FormData();
    data.image && formData.append("image", data.image);
    return axios.post(`${process.env.REACT_APP_API_SERVER_HOST}/upload/thumbnail`, formData, config)
        .then((response) => {
            if (response.data.success === true) {
                return response.data

            }
        })
        .catch((error) => {
            return [];
        });
}


function getScheduleVideos(channelId) {

    const customConfig = {
        headers: {
            pubid: localStorage.getItem('pub_id'),
            channelid: channelId
        },
        params: {
            pub_id: localStorage.getItem('pub_id')
        }
    };

    return axios
        .get(process.env.REACT_APP_API_SERVER_HOST + '/video/list?type=schedule_dropdown&dropdown=linear', customConfig)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return [];
        });

}

function getChannels() {

    const pubid = localStorage.getItem('pub_id');

    const customConfig = {
        headers: {
            pubid: localStorage.getItem('pub_id'),
        }
    };

    return axios
        .get(process.env.REACT_APP_API_SERVER_HOST + '/channel/list/' + pubid, customConfig)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return [];
        });
}

const getCategories = () => {
    let url = `${process.env.REACT_APP_API_SERVER_HOST}/category/list?dropdown=1`
    return axios.get(url, { headers: { 'pubid': localStorage.getItem('pub_id') } }).then(response => {
        return response.data

    }).catch(e => { return { data: false } })
}

function playerToken() {
    const customConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": true,
        crossorigin: true,
        "access-token": localStorage.getItem("access-token"),
      },
    };
    return axios
      .get("https://poppo.tv/proxy/api/GenerateToken", customConfig)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return [];
      });
  }

function getSingleVideos(channelId) {
    const customConfig = {
      headers: {
        pubid: localStorage.getItem("pub_id"),
        channelid: channelId,
        role_id: localStorage.getItem("role_id"),
        content_partner_id: localStorage.getItem("contentPartnerId"),
      },
      params: {
        pub_id: localStorage.getItem("pub_id"),
      },
    };
  
    return axios
      .get(process.env.REACT_APP_API_SERVER_HOST + "/video/list", customConfig)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return [];
      });
  }

// function getSingleVideos(channelId) {

//     const customConfig = {
//         headers: {
//             pubid: localStorage.getItem('pub_id'),
//             channelid: channelId
//         },
       
//     };

//     return axios
//         .get(process.env.REACT_APP_API_SERVER_HOST + '/video/list?dropdown=public', customConfig)
//         .then((response) => {
//             return response.data;
//         })
//         .catch((error) => {
//             return [];
//         });

// }


export const service = {
    getChannels,
    getScheduleVideos,
    getCategoryList,
    getCategories,
    uploadImage,
    publishBlog,
    getChannel,
    draftBlog,
    updateDraftBlog,
    deleteBlogList,
    publishBlogList,
    blogDetails,
    videoDetails,
   getSingleVideos,
    playerToken

};

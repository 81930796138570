import React, { Fragment, useEffect, useState } from 'react';
import { Button, Form, Row, Col, FormGroup, CustomInput, Label, CardTitle, Input } from 'reactstrap';
import Loader from '../../common/Loader'
import { Card, CardBody } from 'reactstrap';
import Select from 'react-select';
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { addDays } from "date-fns";
import moment from "moment-timezone";
import { ExportCSV } from '../exportExcel'
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import FalconCardHeader from "../../common/FalconCardHeader";
import { service } from "./service";
import { toast } from 'react-toastify';



const DirectDebitReport = () => {
    const initialDateFormatter = (date, day) => {
        const formattedDate = moment(date).format("MM-DD-YYYY")
        return formattedDate;
    }
    const [tableLoader, setTableLoader] = useState(false)
    const [calenderOpen, setCalenderOpen] = useState(false);
    const [dateCount, setDateCount] = useState(0);
    const [dateRange, setDateRange] = useState([
        {
            endDate: new Date(),
            startDate: addDays(new Date(), -6),
            key: "selection",
        },
    ]);
    const [filter, setFilter] = useState(
        {
            start_date: initialDateFormatter(addDays(new Date(), -6)),
            end_date: initialDateFormatter(new Date().toISOString().slice(0, 10), 0),
            isGatewaySelected: false,
            isFundTypeSelected: false,
            isPaymentTypeSelected: false,
        }
    )
    const [reportData, setReportData] = useState([])
    const [excelData, setExcelData] = useState([])
    const [archiveData, setArchiveData] = useState([]);
    const [archiveVisibility, setArchiveVisibility] = useState(false);

    const paymentTypeOptions = [
        { label: 'One Off', value: 'one-off' },
        { label: 'Monthly', value: 'monthly' }
    ]

    const fundTypeOptions = [
        { label: 'General', value: 'general' },
        { label: 'Building Fund', value: 'buildingFund' }
    ]

    const gatewayOptions = [
        { label: 'WorldPay', value: 'worldpay' },
        { label: 'Paypal', value: 'paypal' }
    ]

    //  Table data
    const options = {
        custom: true,
        sizePerPage: 200,
        totalSize: reportData.length
    };

    const handleNextPage = ({ page, onPageChange }) => () => {
        onPageChange(page + 1);
    };

    const handlePrevPage = ({ page, onPageChange }) => () => {
        onPageChange(page - 1);
    };

    const handleFirstPage = ({ page, onPageChange }) => () => {
        onPageChange(1);
    };



    const handleLastPage = ({ sizePerPage, onPageChange, totalSize }) => () => {
        onPageChange(Math.ceil(totalSize / sizePerPage));
    };

    const CustomTotal = ({ sizePerPage, totalSize, page, lastIndex }) => (
        <span>
            {(page - 1) * sizePerPage + 1} to {lastIndex > totalSize ? totalSize : lastIndex} of {totalSize}
        </span>
    );

    const selectRow = {
        mode: "checkbox",
        clickToSelect:true,
        hideSelectColumn: !archiveVisibility,
        selectColumnStyle: {
            justifyContent:'center',
            verticalAlign: 'middle'
          },
          classes: 'align-middle',
          nonSelectableClasses:'align-middle',
          selectColumnPosition: 'left',
        selectionHeaderRenderer: () => 'Archive',
        onSelect: (row, isSelect, rowIndex, e) => {
          if (isSelect === true) {
            setArchiveData([...archiveData.filter((obj) => obj.id !== row.id),{...row}])
          } else {
            setArchiveData((archiveData) => archiveData.filter((x, i) => x.id !== row.id));
          }
        },
    
        onSelectAll: (isSelect, rows, e) => {
          if (isSelect === true) {
            setArchiveData(rows);
          } else setArchiveData([]);
        }
      };

    const columns = [
        {
            dataField: 'slno',
            text: 'Si.no',
            classes: 'border-0 align-middle',
            headerClasses: 'border-0',
            footer: ''
        },
        {
            dataField: 'created_at',
            text: 'Created At',
            classes: 'border-0 align-middle',
            headerClasses: 'border-0',
            sort: true,
            footer: ''
        },
        {
            dataField: 'gift_aid_amount',
            text: 'Gift Aid Amount',
            classes: 'border-0 align-middle',
            headerClasses: 'border-0',
            sort: true,
            footer: ''
        },
        {
            dataField: 'is_gift_aid',
            text: 'Is Gift Aid',
            classes: 'border-0 align-middle',
            headerClasses: 'border-0',
            sort: true,
            footer: ''
        },
        {
            dataField: 'amount',
            text: 'Amount',
            classes: 'border-0 align-middle',
            headerClasses: 'border-0',
            sort: true,
            footer: ''
        },
        {
            dataField: 'first_name',
            text: 'First Name',
            classes: 'border-0 align-middle',
            headerClasses: 'border-0',
            sort: true,
            footer: ''
        },
        {
            dataField: 'surname',
            text: 'Surname',
            classes: 'border-0 align-middle',
            headerClasses: 'border-0',
            sort: true,
            footer: ''
        },
        {
            dataField: 'email',
            text: 'Email',
            classes: 'border-0 align-middle',
            headerClasses: 'border-0',
            sort: true,
            footer: ''
        },
        {
            dataField: 'phone_number',
            text: 'Phone Number',
            classes: 'border-0 align-middle',
            headerClasses: 'border-0',
            sort: true,
            footer: ''
        },
        {
            dataField: 'country',
            text: 'Country',
            classes: 'border-0 align-middle',
            headerClasses: 'border-0',
            sort: true,
            footer: ''
        },
        {
            dataField: 'post_code',
            text: 'Post Code',
            classes: 'border-0 align-middle',
            headerClasses: 'border-0',
            sort: true,
            footer: ''
        },
        {
            dataField: 'address_line_1',
            text: 'Address 1',
            classes: 'border-0 align-middle',
            headerClasses: 'border-0',
            sort: true,
            footer: ''
        },
        {
            dataField: 'address_line_2',
            text: 'Address 2',
            classes: 'border-0 align-middle',
            headerClasses: 'border-0',
            sort: true,
            footer: ''
        },
        {
            dataField: 'address_line_3',
            text: 'Address 3',
            classes: 'border-0 align-middle',
            headerClasses: 'border-0',
            sort: true,
            footer: ''
        },
        {
            dataField: 'town',
            text: 'Town',
            classes: 'border-0 align-middle',
            headerClasses: 'border-0',
            sort: true,
            footer: ''
        },
        {
            dataField: 'notify_by',
            text: 'Notify By',
            classes: 'border-0 align-middle',
            headerClasses: 'border-0',
            sort: true,
            footer: ''
        },
        {
            dataField: 'account_holder',
            text: 'Account Holder',
            classes: 'border-0 align-middle',
            headerClasses: 'border-0',
            sort: true,
            footer: ''
        },
        {
            dataField: 'sort_code',
            text: 'Sort Code',
            classes: 'border-0 align-middle',
            headerClasses: 'border-0',
            sort: true,
            footer: ''
        },
        {
            dataField: 'account_number',
            text: 'Account Number',
            classes: 'border-0 align-middle',
            headerClasses: 'border-0',
            sort: true,
            footer: ''
        },
        {
            dataField: 'house_number',
            text: 'House Number',
            classes: 'border-0 align-middle',
            headerClasses: 'border-0',
            sort: true,
            footer: ''
        },
        {
            dataField: 'donate_at',
            text: 'Donate At',
            classes: 'border-0 align-middle',
            headerClasses: 'border-0',
            sort: true,
            footer: ''
        }
    ]

    useEffect(() => {
        fetchDirectDebitData();
    }, [])

    const dateRangeHandleChange = (item) => {

        let start_date = initialDateFormatter(item?.selection?.startDate, 0)
        let end_date = initialDateFormatter(item?.selection?.endDate, 0)

        let cl = document.getElementsByClassName("rdrStaticRange")
        if (
            (
                start_date == initialDateFormatter(new Date(), 0) &&
                end_date == initialDateFormatter(new Date(), 0)
            ) ||
            (
                start_date == initialDateFormatter(new Date(Date.now() - 864e5), 0)
                && end_date == initialDateFormatter(new Date(Date.now() - 864e5), 0)
            )) {
            if (dateCount == 1) {
                setFilter({
                    ...filter,
                    start_date: initialDateFormatter(item?.selection?.startDate, 0),
                    end_date: initialDateFormatter(item?.selection?.endDate, 0),
                })
                setDateCount(0)
                setCalenderOpen(false)
            }
        } else {

            Array.from(cl).forEach(element => {
                {

                    {
                        if (element.className == 'rdrStaticRange rdrStaticRangeSelected' && (element.id != "id0" || element.id != "id1")) {
                            setDateCount(0)
                            setFilter({
                                ...filter,
                                start_date: initialDateFormatter(item?.selection?.startDate, 0),
                                end_date: initialDateFormatter(item?.selection?.endDate, 0),
                            })

                            setCalenderOpen(false)

                        }
                    }
                }//)
            }

            );


        }

        if (dateCount == 1) {
            setFilter({
                ...filter,
                start_date: initialDateFormatter(item?.selection?.startDate, 0),
                end_date: initialDateFormatter(item?.selection?.endDate, 0),
            })
            setDateCount(0)
            setCalenderOpen(false)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        fetchDirectDebitData();
    }

    const fetchDirectDebitData = async () => {
        setCalenderOpen(false)
        setTableLoader(true)
        const {
            start_date,
            end_date,
            gateway,
            fundType,
            paymentType
        } = filter

        const params = {
            start_date,
            end_date,
            gateway: gateway?.value,
            fund_type: fundType?.value,
            payment_type: paymentType?.value
        }
        const donationData = await service.getDirectDebitData(params)
        setTableLoader(false)
        setArchiveData([]);
        setReportData(donationData?.data?.data)
        let data = [];
        let appdata = {}

      const formattedData = donationData?.data?.data.map((element, index) => {
            appdata = {
               "Created At": element.created_at || "",
               "Gift Aid Amount": element.gift_aid_amount || "",
               "Is Gift Aid": element.is_gift_aid || "",
               "Amount": element.amount || "",
               "First Name": element.first_name || "",
               "Surname": element.surname || "",
               "Email": element.email || "",
               "Phone Number": element.phone_number || "",
               "Country": element.country || "",
               "Post Code": element.post_code || "",
               "Address 1": element.address_line_1 || "",
               "Address 2": element.address_line_2 || "",
               "Address 3": element.address_line_3 || "",
               "Town": element.town || "",
               "Notify By": element.notify_by || "",
               "Account Holder": element.account_holder || "",
               "Sort Code": element.sort_code || "",
               "Account Number": element.account_number || "",
               "House Number": element.house_number || "",
               "Donate At": element.donate_at || ""
             };
             appdata = Object.fromEntries(
               Object.entries(appdata).filter(([_, value]) => value !== "")
             );
             data.push(appdata);
             })
       setExcelData(data)
    }

    const fetchDirectDebitDataWithoutLoader = async () => {
        setCalenderOpen(false)
        const {
            start_date,
            end_date,
            gateway,
            fundType,
            paymentType
        } = filter
        const params = {
            start_date,
            end_date,
            gateway: gateway?.value,
            fund_type: fundType?.value,
            payment_type: paymentType?.value
        }
        const donationData = await service.getDirectDebitData(params)
        setArchiveData([]);
        setReportData(donationData?.data?.data)
        let data = [];
        let appdata = {}

        const formattedData = donationData?.data?.data.map((element, index) => {
            appdata = {
               "Created At": element.created_at || "",
               "Gift Aid Amount": element.gift_aid_amount || "",
               "Is Gift Aid": element.is_gift_aid || "",
               "Amount": element.amount || "",
               "First Name": element.first_name || "",
               "Surname": element.surname || "",
               "Email": element.email || "",
               "Phone Number": element.phone_number || "",
               "Country": element.country || "",
               "Post Code": element.post_code || "",
               "Address 1": element.address_line_1 || "",
               "Address 2": element.address_line_2 || "",
               "Address 3": element.address_line_3 || "",
               "Town": element.town || "",
               "Notify By": element.notify_by || "",
               "Account Holder": element.account_holder || "",
               "Sort Code": element.sort_code || "",
               "Account Number": element.account_number || "",
               "House Number": element.house_number || "",
               "Donate At": element.donate_at || ""
             };
             appdata = Object.fromEntries(
               Object.entries(appdata).filter(([_, value]) => value !== "")
             );
             data.push(appdata);
             })
       setExcelData(data)
    }
// archive submit functionality
const archiveDonations =( )=>{
    let donationIDs=[]
    archiveData.map((data)=>{
        donationIDs.push({id:data.id});
    })
    service.archiveDonationData(donationIDs).then(async data=>{
        toast.success(data.message);
        await fetchDirectDebitDataWithoutLoader();
    }).catch(error=>{toast.error("Something went wrong")})
}

    return (<CardBody className="bg-light">

        <FalconCardHeader title="Direct Debit Report" light={false} />
        <Form onSubmit={handleSubmit}>

            {/* <Row noGutters className="checkBox" >

                <Col>
                    <CustomInput
                        id={"gateway"}
                        label={<Fragment>Gateway</Fragment>}
                        checked={filter.isGatewaySelected}
                        type="checkbox"
                        onChange={({ target }) => {
                            setFilter({
                                ...filter,
                                isGatewaySelected: target.checked

                            })
                        }}
                    />
                </Col>
                <Col>
                    <CustomInput
                        id={"fundType"}
                        label={<Fragment>Fund Type</Fragment>}
                        checked={filter.isFundTypeSelected}
                        type="checkbox"
                        onChange={({ target }) => {
                            setFilter({
                                ...filter,
                                isFundTypeSelected: target.checked

                            })
                        }}
                    />
                </Col>
                <Col>
                    <CustomInput
                        id={"paymentType"}
                        label={<Fragment>Payment Type</Fragment>}
                        checked={filter.isPaymentTypeSelected}
                        type="checkbox"
                        onChange={({ target }) => {
                            setFilter({
                                ...filter,
                                isPaymentTypeSelected: target.checked

                            })
                        }}
                    />
                </Col>
            </Row> */}

            <FormGroup>
                <Row noGutters>
                    <Col lg={3} className="pr-lg-2 dateInputNew">
                        <Label for="exampleName">Date Range</Label>
                        <Input
                            onClick={() => {
                                setDateCount(0)
                                setCalenderOpen(true)
                            }}
                            readOnly
                            value={filter.start_date + " - " + filter.end_date}

                        />
                        {calenderOpen ? (
                            <div >
                                <DateRangePicker

                                    onChange={(item) => {
                                        setDateRange([item.selection]);
                                        let datecount = dateCount + 1
                                        setDateCount(datecount)
                                        setTimeout(() => {
                                            dateRangeHandleChange(item)
                                        }, 100);
                                    }}
                                    showPreview={false}
                                    showSelectionPreview={false}
                                    months={1}
                                    ranges={dateRange}
                                    direction="vertical"
                                    fixedHeight={false}

                                />
                                <div className='applyDiv'
                                >
                                </div>
                            </div>
                        ) : null}
                    </Col>
                    {filter.isGatewaySelected && <Col lg={2} className="pr-lg-2" >
                        <Label for="exampleName">Gateway</Label>
                        <Select
                            value={filter.gateway}
                            onChange={(value) => {
                                setFilter({ ...filter, gateway: value })
                            }}
                            classNamePrefix='react-select'
                            options={gatewayOptions}
                        />
                    </Col>}
                    {filter.isFundTypeSelected && <Col lg={2} className="pr-lg-2" >
                        <Label for="exampleName">Fund Type</Label>
                        <Select
                            value={filter.fundType}
                            onChange={(value) => {
                                setFilter({ ...filter, fundType: value })
                            }}
                            classNamePrefix='react-select'
                            options={fundTypeOptions}
                        />
                    </Col>}
                    {filter.isPaymentTypeSelected && <Col lg={2} className="pr-lg-2" >
                        <Label for="exampleName">Payment Type</Label>
                        <Select
                            value={filter.paymentType}
                            onChange={(value) => {
                                setFilter({ ...filter, paymentType: value })
                            }}
                            classNamePrefix='react-select'
                            options={paymentTypeOptions}
                        />
                    </Col>}
                    <Col lg={2} className="pr-lg-2" ><Button type="submit" color="primary" style={{ marginTop: '2rem' }} >Apply Filter</Button></Col>
                    <Col lg={2} className="pr-lg-2" ><Button onClick={(e)=>{e.preventDefault();setArchiveVisibility(!archiveVisibility)}} color="primary" style={{ marginTop: '2rem' }} >Migrate</Button></Col>

                </Row>
            </FormGroup>

            {tableLoader ? <Loader /> : reportData.length !== 0 ? <Fragment>

                <Row noGutters style={{ margin: '3rem 0 0 0' }}>
                    <Col md={12} className="pr-lg-3">

                        <CardBody>

                            <PaginationProvider pagination={paginationFactory(options)}>
                                {({ paginationProps, paginationTableProps }) => {
                                    const lastIndex = paginationProps.page * paginationProps.sizePerPage;

                                    return (
                                        <Fragment>
                                            <div className="table-responsive">
                                                <BootstrapTable

                                                    bootstrap4
                                                    keyField="id"
                                                    data={reportData.map((element, index) => { return { ...element, slno: index + 1 } })}
                                                    columns={columns}
                                                    selectRow={selectRow}
                                                    bordered={true}
                                                    condensed={true}
                                                    hover={true}
                                                    tabIndexCell={true}
                                                    striped={true}
                                                    search={true}
                                                    expanded={true}
                                                    expandable={true}
                                                    searchable={true}
                                                    classes="table-dashboard table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                                                    rowClasses="btn-reveal-trigger border-top border-200"
                                                    headerClasses="bg-200 text-900 border-y border-200"
                                                    {...paginationTableProps}
                                                />
                                            </div>
                                            <Row noGutters className="px-1 py-6">
                                                <Col className="pl-3 fs--1">
                                                    <CustomTotal {...paginationProps} lastIndex={lastIndex} />

                                                </Col>

                                                <Col xs="auto" className="pr-1">
                                                    <Button
                                                        color={paginationProps.page === 1 ? 'light' : 'primary'}
                                                        size="sm"
                                                        onClick={handleFirstPage(paginationProps)}
                                                        disabled={paginationProps.page === 1}
                                                        className="px-4 ml-2"
                                                    >First</Button>

                                                    <Button
                                                        color={paginationProps.page === 1 ? 'light' : 'primary'}
                                                        size="sm"
                                                        onClick={handlePrevPage(paginationProps)}
                                                        disabled={paginationProps.page === 1}
                                                        className="px-4 ml-2"
                                                    >Previous</Button>
                                                    <Button
                                                        color={lastIndex >= paginationProps.totalSize ? 'light' : 'primary'}
                                                        size="sm"
                                                        onClick={handleNextPage(paginationProps)}
                                                        disabled={lastIndex >= paginationProps.totalSize}
                                                        className="px-4 ml-2"
                                                    >
                                                        Next </Button>
                                                    <Button
                                                        color={lastIndex >= paginationProps.totalSize ? 'light' : 'primary'}
                                                        size="sm"
                                                        onClick={handleLastPage(paginationProps)}
                                                        disabled={lastIndex >= paginationProps.totalSize}
                                                        className="px-4 ml-2"
                                                    >Last</Button>
                                                </Col>
                                            </Row>
                                        </Fragment>
                                    );
                                }}
                            </PaginationProvider>
                        </CardBody>
                    </Col>
                </Row>


                <ExportCSV csvData={excelData} fileName={"Donation-Direct-Debit-Report"} isDisable={(reportData.length === 0)} />
                {archiveData.length>0 && <><Button color='primary' className='btn btn-primary ml-5' onClick={archiveDonations}>Archive</Button></>}
            </Fragment> : <span>No data available!</span>}
        </Form>
    </CardBody>)
}

export default DirectDebitReport;

const axios = require('axios');
const qs = require('querystring');

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': true,
    crossorigin: true,
  },
};

function login(email, password) {
  const requestBody = {
    email: email,
    password: password,
  };
  console.log("req=",requestBody);
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST+'/Login', qs.stringify(requestBody), config)
    .then((response) => {
      console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}
function forgot(values) {
  const requestBody = {
    email: values.email,
  };
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST+'/forgotPassword', qs.stringify(requestBody), config)
    .then((response) => {
      console.log('user response', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}
function allCount() {
  const user = JSON.parse(localStorage.getItem('user'));
  console.log("user=",user);
  const requestBody = { };
  console.log("req=",requestBody);
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
  };
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST+'/getAllCount', qs.stringify(requestBody), customConfig)
    .then((response) => {
      console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}
function clientListAll() {
  const user = JSON.parse(localStorage.getItem('user'));
  console.log("user=",user);
  const requestBody = { };
  console.log("req=",requestBody);
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
  };
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST+'/clientListAll', qs.stringify(requestBody), customConfig)
    .then((response) => {
      console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}
// eslint-disable-next-line
export const service = {
  login,
  forgot,
  allCount,
  clientListAll
};

import React, { useContext } from 'react';
import { Card, CardBody } from 'reactstrap';
import echarts from 'echarts/lib/echarts';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import { getPosition, getGrays, themeColors, rgbaColor } from '../../../helpers/utils';
import FalconCardHeader from '../../common/FalconCardHeader';
import AppContext from '../../../context/Context';

import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';




const getOption = (xAxisData, yAxisData, isDark, dataName) => {
    const grays = getGrays(true);
    return {
        tooltip: {
            trigger: 'axis',
            padding: [7, 10],
            backgroundColor: grays.white,
            borderColor: grays[100],
            borderWidth: 1,
            textStyle: { color: grays.black },
            transitionDuration: 0,
            position(pos, params, dom, rect, size) {
                return getPosition(pos, params, dom, rect, size);
            },
            color: 'black'
        },
        xAxis: {
            type: 'category',
            data: xAxisData,
            boundaryGap: false,
            axisPointer: {
                lineStyle: {
                    color: grays['300'],
                    type: 'dashed'
                }
            },
            splitLine: { show: false },
            axisLine: {
                lineStyle: {
                    color: rgbaColor('#000', 0.01),
                    type: 'dashed'
                }
            },
            axisTick: { show: false },
            axisLabel: {
                color: grays['400'],
                margin: 15
            }
        },
        yAxis: {
            type: 'value',
            axisPointer: { show: false },
            splitLine: {
                lineStyle: {
                    color: grays['300'],
                    type: 'dashed'
                }
            },
            boundaryGap: false,
            axisLabel: {
                show: true,
                color: grays['400'],
                margin: 15
            },
            axisTick: { show: false },
            axisLine: { show: false }
        },

        series: [
            {
                type: 'line',
                data: yAxisData.map(element => {

                    if (Number.isInteger(Number(element))) {
                        return element
                    }
                   else if (Number.isFinite(Number(element))) {
                        return parseFloat(element).toFixed(2)
                    }
                    //  else {
                    //     return element? element.toFixed(2): 0
                    // }
                }),
                lineStyle: { color: themeColors.primary },
                itemStyle: {
                    color: themeColors.primary,
                    borderColor: themeColors.primary,
                    borderWidth: 2
                },
                //  name: 'name of item',
                symbol: 'circle',
                symbolSize: 10,
                smooth: false,
                hoverAnimation: true,
                name: dataName,
                areaStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            {
                                offset: 0,
                                color: rgbaColor(themeColors.primary, 0.2)
                            },
                            {
                                offset: 1,
                                color: rgbaColor(themeColors.primary, 0)
                            }
                        ]
                    }
                }
            }
        ],
        legend: {
            show: true,
            data: [dataName],
            textStyle: { color: isDark ? grays.white : grays.black }
        },
        animationDuration: 10,
        grid: { right: '38px', left: '75px', bottom: '15%', top: '10%' }
    };
};

const PointGraph = ({ xAxis, yAxis, title, dataName }) => {
    const { isDark } = useContext(AppContext);
    let body = null
    if (xAxis.length === 0 || yAxis.length === 0) {
        body = <p>No data available!</p>
    } else {
        body = <ReactEchartsCore echarts={echarts} option={getOption(xAxis, yAxis, isDark, dataName)} style={{ minHeight: '18.75rem' }} />
    }
    return (
        <Card >
            <FalconCardHeader title={title} light={false} titleTag="h6" className="pb-0">
            </FalconCardHeader>
            <CardBody className="h-100">
                {body}
            </CardBody>
        </Card>
    );
};


export default PointGraph;

import React, { useState, useEffect } from 'react';
import externalLink from "../../assets/img/dashboard-svgs/external-link-s-svgrepo-com.svg";
import {
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner, // Import Spinner
} from "reactstrap";
import JoditEditor from "jodit-react";
import { toast } from "react-toastify";
import { service } from "./service";
import { Link, useLocation } from "react-router-dom";
import './help.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { service as blogService } from "../blog/service";
// Accordion item component
const AccordionItem = ({ title, content, isOpen, onClick, id, accordionItems, vanity_url, onDelete, onEdit, isSuperAdmin }) => {
  return (
    <div className="accordion-item p-2 m-1" onClick={onClick}>
      <div className="accordion-title d-flex justify-content-between align-items-center help-text-custom">
        <span>{title}</span>
        {
          isSuperAdmin && <div className="accordion-actions">
            <FontAwesomeIcon icon={faEdit} className="mr-2 text-primary icon-pointer" onClick={(e) => { e.stopPropagation(); onEdit(vanity_url); }} />
            <FontAwesomeIcon icon={faTrashAlt} className="text-danger icon-pointer" onClick={(e) => { e.stopPropagation(); onDelete(id); }} />
          </div>
        }

      </div>
      {isOpen && (
        <div className="accordion-content help-accordian-content">
          <div>{content}</div>
          <div>
            <Link
              to={{
                pathname: `/help/read_more/${vanity_url}`,
              }}
              target='_blank'
            >
              <span>Read more </span>
              <img src={externalLink} className='w-12' alt="external link" />
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

// Accordion container component
const Accordion = ({ items, searchTerm, setAccordionItems, setIsShowForm, setFormData, setLoading, isSuperAdmin }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleAccordionClick = index => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const filteredItems = items.filter(item =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleEdit = async (vanity_url) => {
    // Handle edit logic
    setLoading(true)
    setIsShowForm(true)
    if (vanity_url) {
      const response = await service.getQuestion(vanity_url);

      setFormData({ question: response.data[0].question, htmlDescription: response.data[0].answer_html, shortAnswer: response.data[0].short_answer, isFromEdit: true, qs_id: response.data[0].id })
      setLoading(false)
    } else {
      setIsShowForm(false)
      setLoading(false)
    }



  };

  const handleDelete = (id) => {
    // Handle delete logic
    var r = window.confirm("Do you want to delete FAQ?");
    if (r == true) {
      service.deleteQuestion(id).then(response => {
        if (response.success) {
          // Remove the deleted FAQ from the state
          alert('Deleted FAQ');
          setAccordionItems(items.filter(faq => faq.id !== id));
        } else {
          alert('Failed to delete FAQ');
        }
      }).catch(error => {
        console.error('Error deleting FAQ:', error);
        alert('An error occurred while deleting the FAQ');
      });
    }
  };
  return (
    <div className="accordion-container m-3">
      {filteredItems.map((item, index) => (
        <AccordionItem key={index}
          title={item.title}
          content={item.content}
          isOpen={index === openIndex}
          onClick={() => handleAccordionClick(index)}
          id={item.id}
          accordionItems={items}
          vanity_url={item.vanity_url}
          onEdit={handleEdit}
          onDelete={handleDelete}
          isSuperAdmin={isSuperAdmin}
        />
      ))}
    </div>
  );
};

// Help component
const Help = () => {
  const location = useLocation();
  const processURLPath = (path) => {
    if (path.startsWith('/')) {
      path = path.substring(1);
    }
    path = path.replace(/\//g, '_');
    return path;
  };

  const path = processURLPath(location.pathname);
  const [searchTerm, setSearchTerm] = useState('');
  const [isShowForm, setIsShowForm] = useState(false);
  // const [isFromEdit, setIsFromEdit] = useState(false);
  const [formData, setFormData] = useState({ question: '', htmlDescription: '', shortAnswer: '', isFromEdit: false });
  const [accordionItems, setAccordionItems] = useState([]);
  const [url, setUrl] = useState();
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(true); // Loader state
  const [errors, setErrors] = useState({});
  const handleSearch = event => {
    setSearchTerm(event.target.value);
  };

  const isSuperAdmin = localStorage.getItem("superadmin") ? true : false;

  useEffect(() => {

    fetchQuestions();
  }, [path]);

  const fetchQuestions = async () => {
    try {
      setLoading(true); // Show loader during data fetching
      const response = await service.listQuestion(path);
      if (response && response.data) {
        const items = response.data.map(item => ({
          title: item.question,
          content: item.short_answer,
          id: item.id,
          html_answer: item.answer_html,
          vanity_url: item.vanity_url
        }));
        setAccordionItems(items);
      }
    } catch (error) {
      console.error('Error fetching questions:', error);
    } finally {
      setLoading(false); // Hide loader after data fetching
    }
  };

  const handleQuestionAdd = () => {
    setIsShowForm(true);
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: ''
    }));
  };

  const handleEditorChange = newContent => {
    setFormData(prevFormData => ({
      ...prevFormData,
      htmlDescription: newContent
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      htmlDescription: ''
    }));
  };

  const validateTextLines = text => {
    const maxWidthChars = 100;
    const totalCharacters = text.length;
    const estimatedLines = Math.ceil(totalCharacters / maxWidthChars);

    if (estimatedLines > 3) {
      return { valid: false };
    }
    return { valid: true };
  };

  const handleFormSubmit = async event => {
    event.preventDefault();
    let newErrors = {};

    if (!formData.question.trim()) {
      newErrors.question = 'Please input the question';
    }

    if (!formData.htmlDescription.trim()) {
      newErrors.htmlDescription = 'Please input the answer.';
    }

    if (!formData.shortAnswer.trim()) {
      newErrors.shortAnswer = 'Please input the short answer.';
    }

    const { valid } = validateTextLines(formData.shortAnswer);
    if (!valid) {
      newErrors.shortAnswer = 'Short answer cannot exceed 3 lines.';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    let inputData = {
      question: formData.question,
      answer_html: formData.htmlDescription,
      short_answer: formData.shortAnswer,
      path: path
    };

    try {
      setLoading(true); // Show loader during form submission
      let res;
      if (formData.isFromEdit) {
        inputData.id = formData.qs_id
        res = await service.updateQuestion(inputData);
      } else {
        res = await service.addQuestion(inputData);
      }
      setIsShowForm(false);
      // toast.success(res.data);
      setFormData({ question: '', htmlDescription: '', shortAnswer: '', qs_id: '' });
      fetchQuestions();
    } catch (error) {
      console.error('Error adding question:', error);
    } finally {
      setLoading(false); // Hide loader after form submission
    }
  };

  const handleCancel = event => {
    event.preventDefault();
    setIsShowForm(false);
    setErrors({});
    setFormData({ question: '', htmlDescription: '', shortAnswer: '', qs_id: '' });

  };
  const handleFileChange = (e) => {

    setUrl();

    const { name, files } = e.target;
    const values = {
      [name]: files[0],
    };

    blogService.uploadImage(values).then((response) => {

      if (response.success === true) {
        setUrl(response.data[0].path);
        toast.success('File URL copied to clipboad');
      } else {
        toast.error("Something went wrong ! try again");
      }
    });
  };

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Optional: Clear copied state after a timeout
  };

  function copyText(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  }
  return (
    <div className="overlay-active-help" >
      <div className="overlay-content-help" >
        <div className='col-lg-12 mt-3 d-flex justify-content-between'>
          <div>
            {!isShowForm && <h1 className="ml-1">Help Desk</h1>}
          </div>
          <div>
            {isSuperAdmin && !isShowForm && (
              <Button color="primary" onClick={handleQuestionAdd}>Add</Button>
            )}
          </div>
        </div>

        {loading ? (
          <div className="spinner-container" >
            <Spinner color="primary" />
          </div>
        ) : (
          isShowForm ? (
            <Form className="p-4">
              <FormGroup row className="d-flex justify-content-between align-items-center px-3 py-2">
                <div>
                  <h1 class="mt-1">Support</h1>

                </div>


                {url && <div>
                  {!copied && <span className='file-upload-status-close' onClick={() => { setUrl() }}>X</span>}
                  <div className='file-upload-status'>
                    <p>Success: File uploaded successfully.</p>
                    <CopyToClipboard text={url} onCopy={handleCopy}>
                      <span className='copy-to-clipboard'>Copy Link</span>
                    </CopyToClipboard>
                    {copied && <p className='ml-2'> File URL copied to clipboard!</p>}
                  </div>
                </div>}
                <div>
                </div>
                <div>
                  <Button color="secondary" className="mr-2" onClick={handleCancel}>Cancel</Button>
                  <Button color="primary" onClick={handleFormSubmit}>Submit</Button>

                </div>
              </FormGroup>

              <FormGroup row>

                <Label for="question" sm={2}>Question</Label>

                <Col sm={10}>

                  <Input
                    type="text"
                    name="question"
                    id="question"
                    value={formData.question}
                    onChange={handleInputChange}
                  />
                  {errors.question && <p className="text-danger">{errors.question}</p>}
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="question" sm={2}>Attach file?</Label>

                <Col sm={10}>
                  <input type='file' id="image" name="image" onChange={handleFileChange} />
                </Col>

              </FormGroup>

              <FormGroup row>
                <Label for="htmlDescription" sm={2}>Answer</Label>
                <Col sm={10}>
                  <JoditEditor
                    tabIndex={1}
                    value={formData.htmlDescription}
                    onBlur={handleEditorChange}
                    onChange={handleEditorChange}
                  />
                  {errors.htmlDescription && <p className="text-danger">{errors.htmlDescription}</p>}
                </Col>

              </FormGroup>
              <FormGroup row>
                <Label for="shortAnswer" sm={2}>Short Answer</Label>
                <Col sm={10}>
                  <Input
                    type="textarea"
                    name="shortAnswer"
                    id="shortAnswer"
                    value={formData.shortAnswer}
                    onChange={handleInputChange}
                  />
                  {errors.shortAnswer && <p className="text-danger">{errors.shortAnswer}</p>}
                </Col>
              </FormGroup>


            </Form>
          ) : (
            accordionItems.length > 0 ? (
              <>
                <div className='col-lg-12 mt-5'>
                  <input
                    type="text"
                    placeholder="Search...."
                    value={searchTerm}
                    onChange={handleSearch}
                    className='p-2 w-100 support-search'
                  />
                </div>
                <Accordion items={accordionItems} searchTerm={searchTerm} setAccordionItems={setAccordionItems} isShowForm={isShowForm} setIsShowForm={setIsShowForm} setFormData={setFormData}
                  setLoading={setLoading}
                  isSuperAdmin={isSuperAdmin}
                />
              </>
            ) : (
              <div className='no-data'><p>Coming soon !!</p></div>

            )
          )
        )}
      </div>
    </div>
  );
};

export default Help;
